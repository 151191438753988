
import { defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { updateDeviceData } from "@/store/api/devices";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    varientData: { type: Object, required: true },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const payload = ref<any>([]);
    watch(
      () => props.varientData,
      (v) => {
        payload.value = v;
      }
    );

    const loravalidationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      variant_id: Yup.string().required().label("Variant Id"),
      unique_random_number: Yup.string()
        .required()
        .label("Unique Random Number"),
      product_count: Yup.string().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      device_eui: Yup.string().required().label("Device Eui"),
      application_eui: Yup.string().required().label("Application Eui"),
      application_key: Yup.string().required().label("Application Key"),
      application_session_key: Yup.string().label("Application session key"),
      network_session_key: Yup.string().label("Network session key"),
      firmware_version: Yup.string().label("Firmware version"),
      sensor: Yup.string().label("sensor Type"),
    });
    const nbiotvalidationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      variant_id: Yup.string().required().label("Imei"),
      unique_random_number: Yup.string()
        .required()
        .label("Unique Random Number"),
      product_count: Yup.string().required().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      firmware_version: Yup.string().label("Firmware Version"),
      sensor: Yup.string().label("Sensor Type"),
      icc: Yup.string().required().label("ICCID"),
      antenna: Yup.string().label("antenna"),
      batter: Yup.string().label("batter"),
      gasket: Yup.string().label("Gasket"),
      screws: Yup.string().label("Screws"),
      conformal_coating: Yup.string().label("Conformal Coating"),
      silicon_between_lid_and_housing: Yup.string().label(
        "Silicon Between lid and housing"
      ),
      payload_specification_document_version:
        Yup.string().label("Payload version"),
      sealant_on_tunnel: Yup.string().label("Sealant On Tunel"),
      casing_batch_number: Yup.string().label("Casing Batch Number"),
    });
    const sigfoxvalidationSchema = Yup.object().shape({
      variant: Yup.string().label("Variant"),
      variant_id: Yup.string().required().label("Variant Id"),
      unique_random_number: Yup.string()
        .required()
        .label("Unique Random Number"),
      product_count: Yup.string().required().label("Product Count"),
      serial_number: Yup.string().required().label("Serial Number"),
      firmware_version: Yup.string().label("Firmware Version"),
      sensor: Yup.string().label("Sensor Type"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      updateDeviceData(payload.value.device_id, payload.value).then((res) => {
        console.log("device result updated", res.data);
        setTimeout(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            text: `${res.data.message}`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            document.getElementById("editdevicedata")?.click();
          });
        }, 2000);
      });
    };

    return {
      sigfoxvalidationSchema,
      loravalidationSchema,
      nbiotvalidationSchema,
      submit,
      payload,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
    };
  },
});
